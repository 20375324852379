import React, { useState } from 'react';
import './Projects.css'; // Make sure this CSS is suited for the new features
import { Link } from 'react-router-dom';

function createMarkup(htmlContent) {
    return { __html: htmlContent };
}

function Projects() {
    const [expandedId, setExpandedId] = useState(null); // State to manage expanded card

    const projectList = [
        {
            id: 4, // New project ID (now at the top)
            title: "Smart Thermostat Controller",
            briefDescription: "A smart thermostat controller using Arduino, 433 MHz RF module, and DHT11 sensor to automate heating based on temperature thresholds.",
            extensiveDescription: `This project implements a smart thermostat controller that uses an Arduino, a 433 MHz RF module, and a DHT11 temperature sensor to automate heating control:
                <ul>
                    <li><strong>Temperature Monitoring:</strong> Uses a DHT11 sensor to measure ambient temperature.</li>
                    <li><strong>RF Control:</strong> Communicates with a heating device using 433 MHz RF signals.</li>
                    <li><strong>Thermostat Logic:</strong> Turns the heating on when the temperature drops below 18°C and off when it rises above 22°C.</li>
                    <li><strong>Serial Monitoring:</strong> Provides real-time feedback via the serial monitor for debugging and monitoring.</li>
                </ul>
                This project is perfect for anyone looking to automate their heating system and optimize energy usage.`,
            imageUrl: "/arduino.jpg", // Use the image URL you provided
            readMoreLink: "#",
            githubLink: "https://github.com/PaulSpudys/Thermostat" // Link to your GitHub repository
        },
        {
            id: 3,
            title: "Fuel Management iPhone App",
            briefDescription: "An iOS application designed to help users track and optimize fuel usage, providing real-time cost insights and find stations with best prices.",
            extensiveDescription: `The Fuel Management iPhone App is an innovative tool crafted specifically for iOS users to seamlessly monitor and manage fuel consumption:
                <ul>
                    <li><strong>Fuel Tracking:</strong> Log your fuel refills and consumption to get detailed insights into your vehicle’s performance over time.</li>
                    <li><strong>Expense Analytics:</strong> Get detailed cost breakdowns and trends to optimize your fuel expenditure and improve budgeting.</li>
                    <li><strong>Station finder:</strong> Find nearest stations with the best fuel prices saving you time and money.</li>
                </ul>
                With an intuitive interface and robust features, the Fuel Management app empowers users to drive smarter and save more. Perfect for anyone looking to reduce fuel costs and minimize environmental impact.`,
            imageUrl: "/3.jpeg",
            readMoreLink: "#",
            appStoreLink: "https://apps.apple.com/your-app-link"
        },
        {
            id: 1,
            title: "Mileage Calculator Web App",
            briefDescription: "The Mileage Calculator is an intuitive application designed to efficiently track and calculate mileage and fuel usage. It helps users manage their travel costs with precision and ease.",
            extensiveDescription: `Our Mileage Calculator app is more than just a simple travel tool. It offers a comprehensive suite of features to optimize your driving experience and fuel economy:
                <ul>
                    <li><strong>Mileage Calculation:</strong> Quickly compute the distance traveled using vehicle-specific fuel efficiency metrics, allowing for accurate budgeting and expense tracking.</li>
                    <li><strong>Travel History:</strong> Maintain a detailed log of all past calculations. This feature helps users analyze their travel patterns and fuel usage over time, making it possible to optimize driving habits and routes.</li>
                    <li><strong>Petrol Station Finder:</strong> A built-in search feature that helps users locate nearby petrol stations, providing real-time directions and distance estimates.</li>
                </ul>
                This tool is ideal for anyone from casual drivers to frequent travelers who need to manage their automotive expenses better. Whether you’re planning a road trip or tracking your daily commute, our Mileage Calculator has you covered.`,
            imageUrl: "/1.jpeg",
            readMoreLink: "#",
            liveLink: "https://powerful-beyond-99316-8a9857e6a58c.herokuapp.com/"
        },
        {
            id: 2,
            title: "Discord to Google Sheets Bot",
            briefDescription: "A Discord bot that interacts with Google Sheets to update and manage data based on user commands in specific channels.",
            extensiveDescription: `This Discord bot enhances group interactions by integrating with Google Sheets to manage data via user commands in Discord channels. It is built using Python with discord.py for managing Discord interactions and gspread for Google Sheets operations. Users input commands prefixed with '!' to update or retrieve data from a designated Google Sheet, making this bot ideal for groups needing to manage collaborative data such as attendance, distributions, or scores. 
                The bot includes detailed command parsing, robust error handling to maintain data integrity, and secure operations using environment variables for sensitive information like API tokens and credentials. Designed for scalability and maintainability, this bot can be easily updated or extended with minimal effort, making it a dynamic solution adaptable to various group needs.`,
            imageUrl: "/2.jpeg",
            readMoreLink: "#",
            githubLink: "https://github.com/PaulSpudys/DiscordDkpBot"
        }
    ];

    const toggleExpanded = (id) => {
        setExpandedId(expandedId === id ? null : id); // Toggle expansion
    };

    return (
        <div className="projects-container">
            {projectList.map((project) => (
                <div key={project.id} className="project">
                    <img src={project.imageUrl} alt={project.title} className="project-image" />
                    <div className="project-info">
                        <h3>{project.title}</h3>
                        <div dangerouslySetInnerHTML={createMarkup(expandedId === project.id ? project.extensiveDescription : project.briefDescription)} />
                        <div className="button-container">
                            <button onClick={() => toggleExpanded(project.id)} className="apple-style-button blue-button">
                                {expandedId === project.id ? 'Show Less' : 'Read More'}
                            </button>
                            {project.id === 1 && (
                                <>
                                    <Link to={`/projects/${project.id}`} className="apple-style-button blue-button">
                                        Learn More
                                    </Link>
                                    <a href={project.liveLink} target="_blank" rel="noopener noreferrer" className="apple-style-button white-button">
                                        Visit Site
                                    </a>
                                </>
                            )}
                            {project.id === 2 && (
                                <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="apple-style-button white-button">
                                    Visit GitHub
                                </a>
                            )}
                            {project.id === 4 && ( // New button for the Thermostat project
                                <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="apple-style-button white-button">
                                    Visit GitHub
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Projects;